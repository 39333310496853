import React from 'react';
import './Step1.css';
import Navbar from '../Home/Navbar';
import { useTranslation } from 'react-i18next';
import bg from '../Images/bgStepper.webp';

const Step1 = ({
  boattrip,
  setBoatTrip,
  setStep,
}: {
  boattrip?: any;
  setBoatTrip: any;
  setStep: any;
}) => {
  const [disabledButton, setDisableButton] = React.useState(boattrip.numberOfGuests === '');
  const handleNumberOfGuestsClick = (option: any) => {
    setBoatTrip((prevBoatTrip: any) => ({
      ...prevBoatTrip,
      numberOfGuests: option,
    }));
    setDisableButton(false);
  };
  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.stepper.${value}`);
  // const [isFocused, setIsFocused] = useState(false);

  // const handleFocus = () => {
  //   setIsFocused(true);
  // };

  return (
    <div className="step1-container">
      <img src={bg} alt="background image" className='step1-image'/>
      <div className='step1-absolute'>
        <Navbar />
        <div className="step1Header">
          <div className="step1Seperator-hidden" />
          <div className="step1Title">{t('step1Title')}</div>
          <div className="step1Seperator-hidden" />
          <div className="step1Seperator" />
        </div>
        <div className="step1Content">
          <button
            className={'step1OptionsButton'}
            onClick={() => handleNumberOfGuestsClick('0-10 people')}
            aria-label='Option card'
          >
            {t('step1Option1')}
          </button>
          <button
            className="step1OptionsButton"
            onClick={() => handleNumberOfGuestsClick('10-15 people')}
            aria-label='Option card'
          >
            {t('step1Option2')}
          </button>
          <button
            className="step1OptionsButton"
            onClick={() => handleNumberOfGuestsClick('15+ people')}
            aria-label='Option card'
          >
            {t('step1Option3')}
          </button>
        </div>
        <div className="step1ButtonSection">
          <button
            className={`${disabledButton === true ? 'disabledButton' : 'stepperNextButton'}`}
            onClick={() => setStep(2)}
            disabled={disabledButton}
            aria-label='Option card'
          >
            {t('nextBttn')}
          </button>
        </div>
      </div>
      <div className="stepsSection">
        <svg
          className='step1-svg'
          viewBox="0 0 98 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M86.5249 33.3849L95.3454 20.1544H60.0639V13.5391H75.4996L71.0894 6.92385L42.4231 6.92385L38.0129 0.308594L31.3976 0.308594L35.8079 6.92385H24.7824L20.3721 13.5391H26.9874L22.5774 20.1544H15.9621L7.14181 33.3849H0.526367L0.526367 40.0001L97.5504 40.0001V33.3849H86.5249ZM53.4486 20.1544H35.8079V13.5391L53.4486 13.5391V20.1544Z"
            fill="#F1ECE1"
          />
        </svg>

        <svg
          className='step1-arrow-svg'
          viewBox="0 0 134 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.38818 3.92849C0.967378 3.92849 0.626248 4.26962 0.626248 4.69043C0.626248 5.11124 0.967378 5.45237 1.38818 5.45237L1.38818 3.92849ZM133.965 4.69042L126.346 0.291381L126.346 9.08946L133.965 4.69042ZM1.38818 5.45237L127.108 5.45235L127.108 3.92848L1.38818 3.92849L1.38818 5.45237Z"
            fill="#F1ECE1"
          />
        </svg>

        <svg
          className='step1-svg'
          viewBox="0 0 98 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.2">
            <path
              d="M86.604 33.3849L95.4245 20.1544H60.143V13.5391H75.5787L71.1685 6.92385L42.5022 6.92385L38.092 0.308594L31.4767 0.308594L35.887 6.92385H24.8615L20.4512 13.5391H27.0665L22.6565 20.1544H16.0412L7.22092 33.3849H0.605469L0.605469 40.0001L97.6295 40.0001V33.3849H86.604ZM53.5277 20.1544H35.887V13.5391L53.5277 13.5391V20.1544Z"
              fill="#F1ECE1"
            />
          </g>
        </svg>

        <svg
          className='step1-arrow-svg'
          viewBox="0 0 134 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.38818 3.92849C0.967378 3.92849 0.626248 4.26962 0.626248 4.69043C0.626248 5.11124 0.967378 5.45237 1.38818 5.45237L1.38818 3.92849ZM133.965 4.69042L126.346 0.291381L126.346 9.08946L133.965 4.69042ZM1.38818 5.45237L127.108 5.45235L127.108 3.92848L1.38818 3.92849L1.38818 5.45237Z"
            fill="#F1ECE1"
          />
        </svg>

        <svg
          className='step1-svg'
          viewBox="0 0 98 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.2">
            <path
              d="M86.604 33.3849L95.4245 20.1544H60.143V13.5391H75.5787L71.1685 6.92385L42.5022 6.92385L38.092 0.308594L31.4767 0.308594L35.887 6.92385H24.8615L20.4512 13.5391H27.0665L22.6565 20.1544H16.0412L7.22092 33.3849H0.605469L0.605469 40.0001L97.6295 40.0001V33.3849H86.604ZM53.5277 20.1544H35.887V13.5391L53.5277 13.5391V20.1544Z"
              fill="#F1ECE1"
            />
          </g>
        </svg>

        <svg
          className='step1-arrow-svg'
          viewBox="0 0 134 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.38818 3.92849C0.967378 3.92849 0.626248 4.26962 0.626248 4.69043C0.626248 5.11124 0.967378 5.45237 1.38818 5.45237L1.38818 3.92849ZM133.965 4.69042L126.346 0.291381L126.346 9.08946L133.965 4.69042ZM1.38818 5.45237L127.108 5.45235L127.108 3.92848L1.38818 3.92849L1.38818 5.45237Z"
            fill="#F1ECE1"
          />
        </svg>

        <svg
          className='step1-svg'
          viewBox="0 0 98 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.2">
            <path
              d="M86.604 33.3849L95.4245 20.1544H60.143V13.5391H75.5787L71.1685 6.92385L42.5022 6.92385L38.092 0.308594L31.4767 0.308594L35.887 6.92385H24.8615L20.4512 13.5391H27.0665L22.6565 20.1544H16.0412L7.22092 33.3849H0.605469L0.605469 40.0001L97.6295 40.0001V33.3849H86.604ZM53.5277 20.1544H35.887V13.5391L53.5277 13.5391V20.1544Z"
              fill="#F1ECE1"
            />
          </g>
        </svg>

        <svg
          className='step1-arrow-svg'
          viewBox="0 0 134 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.38818 3.92849C0.967378 3.92849 0.626248 4.26962 0.626248 4.69043C0.626248 5.11124 0.967378 5.45237 1.38818 5.45237L1.38818 3.92849ZM133.965 4.69042L126.346 0.291381L126.346 9.08946L133.965 4.69042ZM1.38818 5.45237L127.108 5.45235L127.108 3.92848L1.38818 3.92849L1.38818 5.45237Z"
            fill="#F1ECE1"
          />
        </svg>

        <svg
          className='step1-svg'
          viewBox="0 0 98 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.2">
            <path
              d="M86.604 33.3849L95.4245 20.1544H60.143V13.5391H75.5787L71.1685 6.92385L42.5022 6.92385L38.092 0.308594L31.4767 0.308594L35.887 6.92385H24.8615L20.4512 13.5391H27.0665L22.6565 20.1544H16.0412L7.22092 33.3849H0.605469L0.605469 40.0001L97.6295 40.0001V33.3849H86.604ZM53.5277 20.1544H35.887V13.5391L53.5277 13.5391V20.1544Z"
              fill="#F1ECE1"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Step1;
