import './Footer.css';
import { Icons } from '../Common/Icons/Icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.footer.${value}`);
  const navigate = useNavigate();

  return (
    <div className={'mainFooterDiv'}>
      <div className={'puntaFooterLogo'}>
        <Icons.PuntaLogo />
        <div>
          PUNTA CANA<br />RENTAL YACHTS
        </div>
      </div>

      <div className={'midStuff'}>
        <div className={'aboutContent'}>
          <div>
            <div onClick={() => navigate('/about')} className='footerLink pointerClick' aria-label='About us'>
              {t('aboutUs')}
            </div>
          </div>
          <div>
            <div onClick={() => navigate('/privacy-policy')} className='footerLink pointerClick' aria-label='Privacy policy'>
              {t('privacy')}
            </div>
          </div>
          <div>
            <div onClick={() => navigate('/terms-and-conditions')} className='footerLink pointerClick' aria-label='Terms & Conditions'>
              {t('terms')}
            </div>
          </div>
        </div>
        <div className={'copyright'}>{t('copyright')}</div>
      </div>

      <div className={'footerIcons'}>
        <a href={`whatsapp://send?phone=+18097574780`} target="_blank" rel="noreferrer" aria-label='Whatsapp icon'>
          <Icons.WhatsappFooterIcon />
        </a>
        <a href='https://www.google.com/maps/place/Casa+de+Campo+Marina,+La+Romana+22000,+Dominican+Republic/@18.3993988,-68.9122161,15.77z/data=!4m22!1m11!3m10!1s0x8ea8aa4fddb6524b:0x7f28f72606914e7f!2sCasa+de+Campo+Resort+and+Villas!5m3!1s2024-01-07!4m1!1i2!8m2!3d18.4208612!4d-68.918855!16s%2Fm%2F04gg_rx!3m9!1s0x8ea8ab2c0fd1306f:0xccf5e2b7d387dcf2!5m3!1s2024-01-07!4m1!1i2!8m2!3d18.398904!4d-68.9010527!16s%2Fg%2F1tgl_0yx?entry=ttu' aria-label='WhatsApp icon'>
          <Icons.LocationFooterIcon />
        </a>
        <a href='https://www.instagram.com/puntacanarentalyachts' aria-label='Instagram icon'>
          <Icons.InstagramFooterIcon />
        </a>
        <a href='tel:+18097574780' aria-label='Phone icon'>
          <Icons.PhoneFooterIcon />
        </a>
        <div onClick={() => navigate('/contact')} className={'pointerClick'} aria-label='Mail icon'>
          <Icons.MailFooterIcon />
        </div>
      </div>

    </div>
  );
};

export default Footer;
