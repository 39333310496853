import React from 'react';
import Navbar from '../Home/Navbar';
import Footer from '../Home/Footer';
import './ThankYou.css'; // Asegúrate de tener los estilos necesarios
const ThankYou: React.FC = () => {
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#114859',
        color: 'white'
      }}
    >
      <Navbar />
      <div className="thankYouBody">
        <h1>Thank You!</h1>
        <p>Your message has been successfully sent. We will contact you soon.</p>
      </div>
      <Footer />
    </div>
  );
};

export default ThankYou;

// Añade esta línea para asegurarte de que TypeScript considere este archivo como un módulo
export {};
