import React from 'react';
import Footer from '../Home/Footer';
import Navbar from '../Home/Navbar';
import './Faq.css';
import plusIcon from '../../assets/images/plusIcon.svg';
import { useState } from 'react';
import { Icons } from '../Common/Icons/Icons';
import { useTranslation } from 'react-i18next';

const Faq = () => {
  const [expanded, setExpanded] = useState(0);

  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.faq.${value}`);

  return (
    <div className='faqDiv'>
      <Navbar />
      <div className='faqMain'>
        <div className='faqHeader'>
          <div className='faqSeperator-hidden' />
          <div className='faqTitle'>{t('header')}</div>
          <div className='faqSeperator' />
        </div>
        <div className='faqLogoBgSection'>
          <div className='faqLogoBg'>
            <Icons.PuntaBg width={650} height={450} />
          </div>
        </div>
        <div className='faqContent'>
          <div className='faqQuestions'>
            <div className='faqRow faq-padding-small-screen'>
              {t('question1')}
              <div className='faqImageAlign' onClick={() => setExpanded(1)}>
                <img src={plusIcon} alt='Plus icon'/>
              </div>
            </div>
            {expanded === 1 && (
              <div className='animate-slide-right duration-1000ms faqBgWhite faq-answer-hidden'>
                {t('answer1')}
              </div>
            )}
            <div className='faqRow faq-padding-small-screen'>
              {t('question2')}
              <div className='faqImageAlign' onClick={() => setExpanded(2)}>
                <img src={plusIcon} alt='Plus icon'/>
              </div>
            </div>
            {expanded === 2 && (
              <div className='animate-slide-right duration-1000ms faqBgWhite faq-answer-hidden'>
                {t('answer2')}
              </div>
            )}
            <div className='faqRow faq-padding-small-screen'>
              {t('question3')}
              <div className='faqImageAlign' onClick={() => setExpanded(3)}>
                <img src={plusIcon} alt='Plus icon'/>
              </div>
            </div>
            {expanded === 3 && (
              <div className='animate-slide-right duration-1000ms faqBgWhite faq-answer-hidden'>
                {t('answer3')}
              </div>
            )}
            <div className='faqRow faq-padding-small-screen'>
              {t('question4')}
              <div className='faqImageAlign' onClick={() => setExpanded(4)}>
                <img src={plusIcon} alt='Plus icon'/>
              </div>
            </div>
            {expanded === 4 && (
              <div className='animate-slide-right duration-1000ms faqBgWhite faq-answer-hidden'>
                {t('answer4')}
              </div>
            )}
            <div className='faqRow faq-padding-small-screen'>
              {t('question5')}
              <div className='faqImageAlign' onClick={() => setExpanded(5)}>
                <img src={plusIcon} alt='Plus icon'/>
              </div>
            </div>
            {expanded === 5 && (
              <div className='animate-slide-right duration-1000ms faqBgWhite faq-answer-hidden'>
                {t('answer5')}
              </div>
            )}
            <div className='faqRow faq-padding-small-screen'>
              {t('question6')}
              <div className='faqImageAlign' onClick={() => setExpanded(6)}>
                <img src={plusIcon} alt='Plus icon'/>
              </div>
            </div>
            {expanded === 6 && (
              <div className='animate-slide-right duration-1000ms faqBgWhite faq-answer-hidden'>
                {t('answer6')}
              </div>
            )}
            <div className='faqRow faq-padding-small-screen'>
              {t('question7')}
              <div className='faqImageAlign' onClick={() => setExpanded(7)}>
                <img src={plusIcon} alt='Plus icon'/>
              </div>
            </div>
            {expanded === 7 && (
              <div className='animate-slide-right duration-1000ms faqBgWhite faq-answer-hidden'>
                {t('answer7')}
              </div>
            )}
            <div className='faqRow faq-padding-small-screen'>
              {t('question8')}
              <div className='faqImageAlign' onClick={() => setExpanded(8)}>
                <img src={plusIcon} alt='Plus icon'/>
              </div>
            </div>
            {expanded === 8 && (
              <div className='animate-slide-right duration-1000ms faqBgWhite faq-answer-hidden'>
                {t('answer8')}
              </div>
            )}
            <div className='faqRow faq-padding-small-screen'>
              {t('question9')}
              <div className='faqImageAlign' onClick={() => setExpanded(9)}>
                <img src={plusIcon} alt='Plus icon'/>
              </div>
            </div>
            {expanded === 9 && (
              <div className='animate-slide-right duration-1000ms faqBgWhite faq-answer-hidden'>
                {t('answer9')}
              </div>
            )}
            <div className='faqRow faq-padding-small-screen'>
              {t('question10')}
              <div className='faqImageAlign' onClick={() => setExpanded(10)}>
                <img src={plusIcon} alt='Plus icon'/>
              </div>
            </div>
            {expanded === 10 && (
              <div className='animate-slide-right duration-1000ms faqBgWhite faq-answer-hidden'>
                {t('answer10')}
              </div>
            )}
            <div className='faqRow faq-padding-small-screen'>
              {t('question11')}
              <div className='faqImageAlign' onClick={() => setExpanded(11)}>
                <img src={plusIcon} alt='Plus icon'/>
              </div>
            </div>
            {expanded === 11 && (
              <div className='animate-slide-right duration-1000ms faqBgWhite faq-answer-hidden'>
                {t('answer11')}
              </div>
            )}
          </div>





          <div className='faqAnswers'>
            <div className='faqRow faq-hidden-small'>
              {expanded === 1 && (
                <div className='animate-slide-right duration-1000ms faqBgWhite'>
                  {t('answer1')}
                </div>
              )}
              <div style={{ height: 30, }} />
            </div>
            <div className='faqRow faq-hidden-small'>
              {expanded === 2 && (
                <div className='animate-slide-right duration-1000ms faqBgWhite'>
                  {t('answer2')}
                </div>
              )}
              <div style={{ height: 30, }} />
            </div>
            <div className='faqRow faq-hidden-small'>
              {expanded === 3 && (
                <div className='animate-slide-right duration-1000ms faqBgWhite'>
                  {t('answer3')}
                </div>
              )}
              <div style={{ height: 30, }} />
            </div>
            <div className='faqRow faq-hidden-small'>
              {expanded === 4 && (
                <div className='animate-slide-right duration-1000ms faqBgWhite'>
                  {t('answer4')}
                </div>
              )}
              <div style={{ height: 30, }} />
            </div>
            <div className='faqRow faq-hidden-small'>
              {expanded === 5 && (
                <div className='animate-slide-right duration-1000ms faqBgWhite'>
                  {t('answer5')}
                </div>
              )}
              <div style={{ height: 30, }} />
            </div>
            <div className='faqRow faq-hidden-small'>
              {expanded === 6 && (
                <div className='animate-slide-right duration-1000ms faqBgWhite'>
                  {t('answer6')}
                </div>
              )}
              <div style={{ height: 30, }} />
            </div>
            <div className='faqRow faq-hidden-small'>
              {expanded === 7 && (
                <div className='animate-slide-right duration-1000ms faqBgWhite'>
                  {t('answer7')}
                </div>
              )}
              <div style={{ height: 30, }} />
            </div>
            <div className='faqRow faq-hidden-small'>
              {expanded === 8 && (
                <div className='animate-slide-right duration-1000ms faqBgWhite'>
                  {t('answer8')}
                </div>
              )}
              <div style={{ height: 30, }} />
            </div>
            <div className='faqRow faq-hidden-small'>
              {expanded === 9 && (
                <div className='animate-slide-right duration-1000ms faqBgWhite'>
                  {t('answer9')}
                </div>
              )}
              <div style={{ height: 30, }} />
            </div>
            <div className='faqRow faq-hidden-small'>
              {expanded === 10 && (
                <div className='animate-slide-right duration-1000ms faqBgWhite'>
                  {t('answer10')}
                </div>
              )}
              <div style={{ height: 30, }} />
            </div>
            <div className='faqRow faq-hidden-small'>
              {expanded === 11 && (
                <div className='animate-slide-right duration-1000ms faqBgWhite'>
                  {t('answer11')}
                </div>
              )}
              <div style={{ height: 30, }} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;
