import React, { useState } from 'react';
import './Step5.css';
import Navbar from '../Home/Navbar';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import bg from '../Images/BgStepper4.webp';
import { useNavigate } from 'react-router-dom';

const Step5 = ({
  boattrip,
  setBoatTrip,
  setStep,
}: {
  boattrip: any;
  setBoatTrip: any;
  setStep: any;
}) => {
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const formatDate = (inputDate: any) => {
    const [year, month, day] = inputDate.split('-');
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const formattedMonth = monthNames[parseInt(month, 10) - 1];
    return `${formattedMonth}/${day}/${year}`;
  };

  const sendEmail = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsSending(true);

    const emailData = {
      numberOfGuests: boattrip.numberOfGuests,
      experience: boattrip.experience,
      destination: boattrip.destination,
      boat: boattrip.boat,
      nameOfTrip: boattrip.description.nameOfTrip,
      date: boattrip.description.date,
      name: boattrip.description.name,
      number: boattrip.description.number,
      email: boattrip.description.email,
      description: boattrip.description.description,
    };
    console.log(emailData, '-------------');

    try {
      await emailjs.send('service_rghv6ys', 'template_zuxlbir', emailData, 'lvUVivcyaCWionIPi');
      setShowThankYou(true);
    } catch (error) {
      console.error('Error sending email:', error);
      setIsSending(false);
    }
  };

  const handleInputChange = (e: any, field: any) => {
    const { value } = e.target;
    if (field === 'date') {
      setBoatTrip((prevBoatTrip: any) => ({
        ...prevBoatTrip,
        description: {
          ...prevBoatTrip.description,
          [field]: formatDate(value),
        },
      }));
    } else {
      setBoatTrip((prevBoatTrip: any) => ({
        ...prevBoatTrip,
        description: {
          ...prevBoatTrip.description,
          [field]: value,
        },
      }));
    }
  };

  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.stepper.${value}`);

  return (
    <div className="step5-container">
      <img src={bg} alt='Background image' className='step5-image' />
      <div className='step5-absolute'>
        <Navbar />
        {showThankYou ? null : (
          <div className="step1Header">
            <div className="step1Seperator-hidden" />
            <div className="step1Title">{t('step5Title')}</div>
            <div className="step1Seperator-hidden" />
            <div className="step1Seperator" />
          </div>
        )}

        {showThankYou ? (
          <div className="thankYouMessage">
            <p style={{ whiteSpace: 'pre-line' }}>
              {t('thankYouMsg')}
              <button
                onClick={()=>{navigate('/');}}
                aria-label='Home'
                className='step5HomeBttn'
              >
                {t('backHome')}
              </button>
            </p>
          </div>
        ) : (
          <form onSubmit={sendEmail}>
            <div className="step5-content">
              <input
                className="step5-input"
                placeholder={t('tripName')}
                value={boattrip.description.nameOfTrip}
                onChange={(e) => handleInputChange(e, 'nameOfTrip')}
              />
              <input
                className="step5-input"
                placeholder={t('tripDate')}
                value={boattrip.description.date.formatDate}
                onChange={(e) => handleInputChange(e, 'date')}
                type='date'
              />
              <input
                className="step5-input"
                placeholder={t('nameLastName')}
                value={boattrip.description.name}
                onChange={(e) => handleInputChange(e, 'name')}
              />
              <input
                className="step5-input"
                placeholder={t('number')}
                value={boattrip.description.number}
                onChange={(e) => handleInputChange(e, 'number')}
              />
              <input
                className="step5-input"
                placeholder={t('tripEmail')}
                value={boattrip.description.email}
                onChange={(e) => handleInputChange(e, 'email')}
              />
              <input
                className="step5-input"
                placeholder={t('tripDescription')}
                value={boattrip.description.description}
                onChange={(e) => handleInputChange(e, 'description')}
              />
            </div>
            <div className="step5ButtonSection">
              <button
                className="step5-stepperBackButton"
                onClick={() => setStep(4)}
                aria-label='Back'
              >
                {t('backBttn')}
              </button>
              <button
                className={`${boattrip.description.nameOfTrip === '' ||
                  boattrip.description.date === '' ||
                  boattrip.description.name === '' ||
                  boattrip.description.number === '' ||
                  boattrip.description.email === '' ||
                  boattrip.description.description === ''
                  ? 'step2-disabledButton disabledClass'
                  : 'step2-stepperNextButton'
                }`}
                type="submit"
                disabled={
                  boattrip.description.nameOfTrip === '' ||
                  boattrip.description.date === '' ||
                  boattrip.description.name === '' ||
                  boattrip.description.number === '' ||
                  boattrip.description.email === '' ||
                  boattrip.description.description === '' ||
                  isSending
                }
                aria-label='Submit'
              >
                {isSending ? t('sendingBttn') : t('doneBttn')}
              </button>
            </div>
          </form>
        )}
      </div>
      {showThankYou ? null : (
        <div className="steps5Section">
          <svg
            className='step4-svg'
            viewBox="0 0 98 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M86.5249 33.3849L95.3454 20.1544H60.0639V13.5391H75.4996L71.0894 6.92385L42.4231 6.92385L38.0129 0.308594L31.3976 0.308594L35.8079 6.92385H24.7824L20.3721 13.5391H26.9874L22.5774 20.1544H15.9621L7.14181 33.3849H0.526367L0.526367 40.0001L97.5504 40.0001V33.3849H86.5249ZM53.4486 20.1544H35.8079V13.5391L53.4486 13.5391V20.1544Z"
              fill="#F1ECE1"
            />
          </svg>

          <svg
            className='step4-arrow-svg'
            viewBox="0 0 134 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.38818 3.92849C0.967378 3.92849 0.626248 4.26962 0.626248 4.69043C0.626248 5.11124 0.967378 5.45237 1.38818 5.45237L1.38818 3.92849ZM133.965 4.69042L126.346 0.291381L126.346 9.08946L133.965 4.69042ZM1.38818 5.45237L127.108 5.45235L127.108 3.92848L1.38818 3.92849L1.38818 5.45237Z"
              fill="#F1ECE1"
            />
          </svg>

          <svg
            className='step4-svg'
            viewBox="0 0 98 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M86.5249 33.3849L95.3454 20.1544H60.0639V13.5391H75.4996L71.0894 6.92385L42.4231 6.92385L38.0129 0.308594L31.3976 0.308594L35.8079 6.92385H24.7824L20.3721 13.5391H26.9874L22.5774 20.1544H15.9621L7.14181 33.3849H0.526367L0.526367 40.0001L97.5504 40.0001V33.3849H86.5249ZM53.4486 20.1544H35.8079V13.5391L53.4486 13.5391V20.1544Z"
              fill="#F1ECE1"
            />
          </svg>

          <svg
            className='step4-arrow-svg'
            viewBox="0 0 134 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.38818 3.92849C0.967378 3.92849 0.626248 4.26962 0.626248 4.69043C0.626248 5.11124 0.967378 5.45237 1.38818 5.45237L1.38818 3.92849ZM133.965 4.69042L126.346 0.291381L126.346 9.08946L133.965 4.69042ZM1.38818 5.45237L127.108 5.45235L127.108 3.92848L1.38818 3.92849L1.38818 5.45237Z"
              fill="#F1ECE1"
            />
          </svg>

          <svg
            className='step4-svg'
            viewBox="0 0 98 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M86.5249 33.3849L95.3454 20.1544H60.0639V13.5391H75.4996L71.0894 6.92385L42.4231 6.92385L38.0129 0.308594L31.3976 0.308594L35.8079 6.92385H24.7824L20.3721 13.5391H26.9874L22.5774 20.1544H15.9621L7.14181 33.3849H0.526367L0.526367 40.0001L97.5504 40.0001V33.3849H86.5249ZM53.4486 20.1544H35.8079V13.5391L53.4486 13.5391V20.1544Z"
              fill="#F1ECE1"
            />
          </svg>

          <svg
            className='step4-arrow-svg'
            viewBox="0 0 134 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.38818 3.92849C0.967378 3.92849 0.626248 4.26962 0.626248 4.69043C0.626248 5.11124 0.967378 5.45237 1.38818 5.45237L1.38818 3.92849ZM133.965 4.69042L126.346 0.291381L126.346 9.08946L133.965 4.69042ZM1.38818 5.45237L127.108 5.45235L127.108 3.92848L1.38818 3.92849L1.38818 5.45237Z"
              fill="#F1ECE1"
            />
          </svg>

          <svg
            className='step4-svg'
            viewBox="0 0 98 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M86.5249 33.3849L95.3454 20.1544H60.0639V13.5391H75.4996L71.0894 6.92385L42.4231 6.92385L38.0129 0.308594L31.3976 0.308594L35.8079 6.92385H24.7824L20.3721 13.5391H26.9874L22.5774 20.1544H15.9621L7.14181 33.3849H0.526367L0.526367 40.0001L97.5504 40.0001V33.3849H86.5249ZM53.4486 20.1544H35.8079V13.5391L53.4486 13.5391V20.1544Z"
              fill="#F1ECE1"
            />
          </svg>

          <svg
            className='step4-arrow-svg'
            viewBox="0 0 134 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.38818 3.92849C0.967378 3.92849 0.626248 4.26962 0.626248 4.69043C0.626248 5.11124 0.967378 5.45237 1.38818 5.45237L1.38818 3.92849ZM133.965 4.69042L126.346 0.291381L126.346 9.08946L133.965 4.69042ZM1.38818 5.45237L127.108 5.45235L127.108 3.92848L1.38818 3.92849L1.38818 5.45237Z"
              fill="#F1ECE1"
            />
          </svg>

          <svg
            className='step4-svg'
            viewBox="0 0 98 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.2">
              <path
                d="M86.604 33.3849L95.4245 20.1544H60.143V13.5391H75.5787L71.1685 6.92385L42.5022 6.92385L38.092 0.308594L31.4767 0.308594L35.887 6.92385H24.8615L20.4512 13.5391H27.0665L22.6565 20.1544H16.0412L7.22092 33.3849H0.605469L0.605469 40.0001L97.6295 40.0001V33.3849H86.604ZM53.5277 20.1544H35.887V13.5391L53.5277 13.5391V20.1544Z"
                fill="#F1ECE1"
              />
            </g>
          </svg>
        </div>
      )}
    </div>
  );
};

export default Step5;
