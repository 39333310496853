import Navbar from '../Home/Navbar';
import React, { useState } from 'react';
import Footer from '../Home/Footer';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './MoreInfo.css';
import backArrow from '../../assets/images/backArrow.svg';
import leftArrow from '../../assets/images/leftArrow.svg';
import rightArrow from '../../assets/images/rightArrow.svg';
import { Icons } from '../Common/Icons/Icons';
import vesselDetailsData from './Json/VesselsDetails.json';
import vesselDetailsDataEs from './Json/VesselsDetailsEs.json';
import i18n from '../../i18n';
const MoreInfo = () => {
  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.vesselsDetails.${value}`);

  const navigate = useNavigate();
  const location = useLocation();
  const vesselId = location.state?.vesselId;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = location.state?.images;
  const selectedVesselsData = i18n.language === 'es' ? vesselDetailsDataEs : vesselDetailsData;
  const selectedVesel = selectedVesselsData.find((vessel: { id: any; }) => vessel.id === vesselId + 1);
  console.log(vesselId);
  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };
  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };
  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };


  const divStyle = {
    width: '100%',
    height: '100vh',
  };
  return (
    <div className='moreInfoDiv' style={divStyle}>
      <Navbar />
      <div >
        <div className='moreInfoContent'>
          <div className='moreInfoLogoBgSection'>
            <div className='moreInfoLogoBg'>
              <Icons.PuntaBg width={650} height={450} />
            </div>
          </div>
          <div className='moreInfoHeader'>
            <div onClick={() => { navigate('/Vessels'); }} className='backArrowContainer'>
              <img src={backArrow} alt='Back arrow' className='backArrow' />
            </div>
            <div className='moreInfoTitle'>
              <div className='moreInfoSeperator-hidden' />
              <div className='moreInfoTitleSmall'>{selectedVesel?.name}</div>
              <div className='moreInfoSeperator-hidden' />
            </div>
            <div className='moreInfoTitleText'>{selectedVesel?.name}</div>
            <div className='moreInfoLineGold' />
          </div>
          <div className='moreInfoMain'>
            <div className='moreInfoCol1'>
              <div style={{ whiteSpace: 'pre-line' }} >{selectedVesel?.moreInfo}</div>
            </div>
            <div className='moreInfoCol2'>
              <div className='moreInfoClick'>*{t('click')}</div>
              <div className="bigImageContainer" onClick={handleImageClick}>
                <img src={require(`../../assets/images/${images[0]}`)} alt="Main Image" className="bigImage" />
              </div>
              <div className='moreInfoCounter'>1/{selectedVesel?.images.length}</div>
              {isModalOpen && (
                <div className="modal-overlay">
                  <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className='modalLeft'>
                      <div onClick={handleModalClose} className='ModalBackArrow'>
                        <img src={backArrow} alt='Back arrow' style={{ cursor: 'pointer' }} />
                      </div>
                      <div onClick={handlePrevClick}>
                        <img src={leftArrow} alt='Left arrow' style={{ cursor: 'pointer' }} />
                      </div>
                    </div>
                    <img src={require(`../../assets/images/${images[currentImageIndex]}`)} alt='Image' className="modal-image" />
                    <div onClick={handleNextClick}>
                      <img src={rightArrow} alt='Right arrow' style={{ cursor: 'pointer' }} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='moreinfo-footer'>
        <Footer />
      </div>
    </div>
  );
};


export default MoreInfo;
