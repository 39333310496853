// Vessels.tsx
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import Navbar from '../Home/Navbar';
import Footer from '../Home/Footer';
import './Vessels.css';
import vesselsData from './Json/Vessels.json';
import vesselsDataEs from './Json/VesselsEs.json';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Vessels = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.vessels.${value}`);
  const selectedVesselsData = language === 'es' ? vesselsDataEs : vesselsData;

  const autoScrollEnabled = useRef(true);

  useEffect(() => {
    const handleUserInteraction = () => {
      autoScrollEnabled.current = false;
    };

    const interval = setInterval(() => {
      if (autoScrollEnabled.current) {
        window.scrollBy({
          top: 10,
          behavior: 'smooth'
        });
        
        if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
          clearInterval(interval);
        }
      }
    }, 100);

    window.addEventListener('click', handleUserInteraction, { passive: true });
    window.addEventListener('touchstart', handleUserInteraction, { passive: true });

    return () => {
      clearInterval(interval);
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    };
  }, []);


  const handleMoreInfoClick = (vessel: { name: string; moreInfo: string; images?: any }, vesselId: number) => {
    // Navigate to the MoreInfo page with the selected vessel data in the state
    navigate('/more-info', {
      state: {
        ...vessel,
        vesselId
      }
    });
    console.log(vessel, vesselId);
  };

  return (
    <div className={'vesselsMain'}>
      <Navbar />
      <div className='vesselsMainHeader'>
        <div className='vesselSeperator-hidden' />
        <div className='faqTitle'>{t('vesselsTitle')}</div>
        <div className='vesselSeperator' />
      </div>
      {selectedVesselsData.map((vessel, vesselId) => (
        <div
          key={vesselId}
          className={`pic${vesselId + 1}VesselDiv`}
        >
          <img src={require(`../../assets/images/${vessel.backgroundImage}`)} alt={vessel.name} className={vesselId === 0 ? 'vessels1Image' : 'vesselsImage'} />
          <div className='vesselsMainContainer'>
            <div className='vesselsPadding'>
              <div className={vessel.headerClass}>
                <div className={`vesselSeperator-hidden${vessel.headerClass === 'vesselsHeaderBlue' ? 'Blue' : 'Gold'}`} />
                <div>{vessel.name}</div>
                <div className={`vesselsLine${vessel.headerClass === 'vesselsHeaderBlue' ? 'Blue' : 'Gold'}`} />
              </div>
              <div className='vesselContainer'>
                <div>
                  <div className={vessel.headerClass === 'vesselsHeaderBlue' ? 'descriptionBlue' : 'description'}>
                    {/*{vessel.description}*/}
                  </div>
                  <div className={'moreInfo'}>
                    <button
                      className={vessel.moreInfoBtnClass}
                      onClick={() => handleMoreInfoClick(vessel, vesselId)}
                      aria-label='More info'
                    >
                      {t('moreInfoBttn')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className='vesselFooter'>
        <Footer />
      </div>
    </div>
  );
};

export default Vessels;
