import React from 'react';
import './Step3.css';
import Navbar from '../Home/Navbar';
import cardimg1 from '../../assets/images/islands/catalina-island.webp';
import cardimg2 from '../../assets/images/islands/saona-island.webp';
import cardimg3 from '../../assets/images/islands/palmilla-beach.webp';
import { useTranslation } from 'react-i18next';
import bg from '../Images/bgStepper2.webp';

const Step3 = ({
  boattrip,
  setBoatTrip,
  setStep,
}: {
  boattrip?: any;
  setBoatTrip: any;
  setStep: any;
}) => {

  const [disabledButton, setDisableButton] = React.useState(boattrip.destination === '');
  const handleDestination = (option: any) => {
    setBoatTrip((prevBoatTrip: any) => ({
      ...prevBoatTrip,
      destination: option,
    }));
    setDisableButton(false);
  };
  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.stepper.${value}`);
  const [isFocused, setIsFocused] = React.useState(0);
  const defaultCard = 'option-card';
  const focusedCard = 'option-card-focused';

  return (
    <div className="step3-container">
      <img src={bg} alt='Background image' className='step3-image'/>
      <div className='step3-absolute'>
        <Navbar />
        <div className="step1Header">
          <div className="step1Seperator-hidden" />
          <div className="step1Title">{t('step3Title')}</div>
          <div className="step1Seperator-hidden" />
          <div className="step1Seperator" />
        </div>
        <div className="step3-cards-section">
          <div
            className={isFocused === 1 ? focusedCard : defaultCard}
            onClick={() => {
              handleDestination('Catalina Island');
              setIsFocused(1);
            }}
          >
            <div className="step3-option-image">
              <div className="image-container">
                <img src={cardimg1} alt='Card image' className={'imageRadius'} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 445 446"
                  fill="none"
                  className="svg"
                >
                  <path
                    d="M0 0.5C0 246.267 199.233 445.5 445 445.5H0V0.5Z"
                    fill="#F1ECE1"
                  />
                </svg>
              </div>
            </div>
            <div className="step3-option-imgText">
              <p className='card-title'>{t('step3Option1Title')}</p>
              <p>
                {t('step3Option1Txt')}
              </p>
            </div>
          </div>
          <div
            className={isFocused === 2 ? focusedCard : defaultCard}
            onClick={() => {
              handleDestination('Saona Island');
              setIsFocused(2);
            }}
          >
            <div className="step3-option-image">
              <div className="image-container">
                <img src={cardimg2} alt='Card image' className={'imageRadius'} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 445 446"
                  fill="none"
                  className="svg"
                >
                  <path
                    d="M0 0.5C0 246.267 199.233 445.5 445 445.5H0V0.5Z"
                    fill="#F1ECE1"
                  />
                </svg>
              </div>
            </div>
            <div className="step3-option-imgText">
              <p className='card-title'>{t('step3Option2Title')}</p>
              <p>
                {t('step3Option2Txt')}
              </p>
            </div>
          </div>
          <div
            className={isFocused === 3 ? focusedCard : defaultCard}
            onClick={() => {
              handleDestination('PALMILLA BEACH');
              setIsFocused(3);
            }}
          >
            <div className="step3-option-image">
              <div className="image-container">
                <img src={cardimg3} alt='Card image' className={'imageRadius'} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 445 446"
                  fill="none"
                  className="svg"
                >
                  <path
                    d="M0 0.5C0 246.267 199.233 445.5 445 445.5H0V0.5Z"
                    fill="#F1ECE1"
                  />
                </svg>
              </div>
            </div>
            <div className="step3-option-imgText">
              <p className='card-title'>{t('step3Option3Title')}</p>
              <p>
                {t('step3Option3Txt')}
              </p>
            </div>
          </div>
        </div>
        <div className="step3ButtonSection">
          <button className="step3-stepperBackButton" onClick={() => setStep(2)} aria-label='Go back'>
            {t('backBttn')}
          </button>
          <button className={`${disabledButton === true ? 'step2-disabledButton' : 'step2-stepperNextButton'}`} onClick={() => setStep(4)} disabled={disabledButton} aria-label='Next'>
            {t('nextBttn')}
          </button>
        </div>
      </div>
      <div className="steps3Section">
        <svg
          className='step3-svg'
          viewBox="0 0 98 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M86.5249 33.3849L95.3454 20.1544H60.0639V13.5391H75.4996L71.0894 6.92385L42.4231 6.92385L38.0129 0.308594L31.3976 0.308594L35.8079 6.92385H24.7824L20.3721 13.5391H26.9874L22.5774 20.1544H15.9621L7.14181 33.3849H0.526367L0.526367 40.0001L97.5504 40.0001V33.3849H86.5249ZM53.4486 20.1544H35.8079V13.5391L53.4486 13.5391V20.1544Z"
            fill="#F1ECE1"
          />
        </svg>

        <svg
          className='step3-arrow-svg'
          viewBox="0 0 134 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.38818 3.92849C0.967378 3.92849 0.626248 4.26962 0.626248 4.69043C0.626248 5.11124 0.967378 5.45237 1.38818 5.45237L1.38818 3.92849ZM133.965 4.69042L126.346 0.291381L126.346 9.08946L133.965 4.69042ZM1.38818 5.45237L127.108 5.45235L127.108 3.92848L1.38818 3.92849L1.38818 5.45237Z"
            fill="#F1ECE1"
          />
        </svg>

        <svg
          className='step3-svg'
          viewBox="0 0 98 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M86.5249 33.3849L95.3454 20.1544H60.0639V13.5391H75.4996L71.0894 6.92385L42.4231 6.92385L38.0129 0.308594L31.3976 0.308594L35.8079 6.92385H24.7824L20.3721 13.5391H26.9874L22.5774 20.1544H15.9621L7.14181 33.3849H0.526367L0.526367 40.0001L97.5504 40.0001V33.3849H86.5249ZM53.4486 20.1544H35.8079V13.5391L53.4486 13.5391V20.1544Z"
            fill="#F1ECE1"
          />
        </svg>

        <svg
          className='step3-arrow-svg'
          viewBox="0 0 134 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.38818 3.92849C0.967378 3.92849 0.626248 4.26962 0.626248 4.69043C0.626248 5.11124 0.967378 5.45237 1.38818 5.45237L1.38818 3.92849ZM133.965 4.69042L126.346 0.291381L126.346 9.08946L133.965 4.69042ZM1.38818 5.45237L127.108 5.45235L127.108 3.92848L1.38818 3.92849L1.38818 5.45237Z"
            fill="#F1ECE1"
          />
        </svg>

        <svg
          className='step3-svg'
          viewBox="0 0 98 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M86.5249 33.3849L95.3454 20.1544H60.0639V13.5391H75.4996L71.0894 6.92385L42.4231 6.92385L38.0129 0.308594L31.3976 0.308594L35.8079 6.92385H24.7824L20.3721 13.5391H26.9874L22.5774 20.1544H15.9621L7.14181 33.3849H0.526367L0.526367 40.0001L97.5504 40.0001V33.3849H86.5249ZM53.4486 20.1544H35.8079V13.5391L53.4486 13.5391V20.1544Z"
            fill="#F1ECE1"
          />
        </svg>

        <svg
          className='step3-arrow-svg'
          viewBox="0 0 134 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.38818 3.92849C0.967378 3.92849 0.626248 4.26962 0.626248 4.69043C0.626248 5.11124 0.967378 5.45237 1.38818 5.45237L1.38818 3.92849ZM133.965 4.69042L126.346 0.291381L126.346 9.08946L133.965 4.69042ZM1.38818 5.45237L127.108 5.45235L127.108 3.92848L1.38818 3.92849L1.38818 5.45237Z"
            fill="#F1ECE1"
          />
        </svg>

        <svg
          className='step3-svg'
          viewBox="0 0 98 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.2">
            <path
              d="M86.604 33.3849L95.4245 20.1544H60.143V13.5391H75.5787L71.1685 6.92385L42.5022 6.92385L38.092 0.308594L31.4767 0.308594L35.887 6.92385H24.8615L20.4512 13.5391H27.0665L22.6565 20.1544H16.0412L7.22092 33.3849H0.605469L0.605469 40.0001L97.6295 40.0001V33.3849H86.604ZM53.5277 20.1544H35.887V13.5391L53.5277 13.5391V20.1544Z"
              fill="#F1ECE1"
            />
          </g>
        </svg>

        <svg
          className='step3-arrow-svg'
          viewBox="0 0 134 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.38818 3.92849C0.967378 3.92849 0.626248 4.26962 0.626248 4.69043C0.626248 5.11124 0.967378 5.45237 1.38818 5.45237L1.38818 3.92849ZM133.965 4.69042L126.346 0.291381L126.346 9.08946L133.965 4.69042ZM1.38818 5.45237L127.108 5.45235L127.108 3.92848L1.38818 3.92849L1.38818 5.45237Z"
            fill="#F1ECE1"
          />
        </svg>

        <svg
          className='step3-svg'
          viewBox="0 0 98 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.2">
            <path
              d="M86.604 33.3849L95.4245 20.1544H60.143V13.5391H75.5787L71.1685 6.92385L42.5022 6.92385L38.092 0.308594L31.4767 0.308594L35.887 6.92385H24.8615L20.4512 13.5391H27.0665L22.6565 20.1544H16.0412L7.22092 33.3849H0.605469L0.605469 40.0001L97.6295 40.0001V33.3849H86.604ZM53.5277 20.1544H35.887V13.5391L53.5277 13.5391V20.1544Z"
              fill="#F1ECE1"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Step3;
