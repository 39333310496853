import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from './locales/en.json';
import common_es from './locales/es.json';

const resources = {
  en: {
    t: common_en,
  },
  es: {
    t: common_es,
  },
};

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources,
});

export const changeLanguage = (lang) => {
  i18n.changeLanguage(lang);
};

export default i18n;