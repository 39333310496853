import React, {
  useState,
  useEffect
} from 'react';
import Footer from '../Home/Footer';
import Navbar from '../Home/Navbar';
import './TermsAndConditions.css';
import { Icons } from '../Common/Icons/Icons';
import { useTranslation } from 'react-i18next';

const TermsAndConditions = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const thresholdWidth = 700;
  const defaultWidth = 400;
  const defaultHeight = 300;

  const calculateImageSize = (width: any) => {
    if (width < thresholdWidth) {
      return {
        width: Math.floor(defaultWidth * 0.8),
        height: Math.floor(defaultHeight * 0.8),
      };
    } else {
      return {
        width: Math.floor(defaultWidth * 1.2),
        height: Math.floor(defaultHeight * 1.2),
      };
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { width, height } = calculateImageSize(screenWidth);
  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.privacy.${value}`);

  return (
    <div className="termsContainer">
      <Navbar />
      <div className="termsLogoBgSection">
        <div className="termsLogoBg">
          <Icons.PuntaBg width={width} height={height} />
        </div>
      </div>
      <div className="termsContent">
        <h1 style={{ textAlign: 'center' }}>{t('title2')}</h1>
        <div>
          {t('privacy1')}
        </div>
        <div className="termsContentDetails">
          <h4>{t('security')}</h4>
          <div>
            {t('security1')}
          </div>
          <div>
            {t('security2')}
          </div>
          <div>
            {t('security3')}
          </div>
          <div>
            {t('security4')}
          </div>
          <div>
            {t('security5')}
          </div>
        </div>
        <div className="termsContentDetails">
          <h4>{t('client')}</h4>
          <div>
            {t('client6')}
          </div>
          <div>
            {t('client7')}
          </div>
          <div>
            {t('client8')}
          </div>

          <div>
            {t('client9')}
          </div>
          <div>
            {t('client10')}
          </div>
        </div>
        <div className="termsContentDetails">
          <h4>{t('activities')}</h4>
          <div>
            {t('activities11')}
          </div>
          <div>
            {t('activities12')}
          </div>
          <div>
            {t('activities13')}
          </div>
          <div>
            {t('activities14')}
          </div>
          <div>
            {t('activities15')}
          </div>
        </div>
        <div className="termsContentDetails">
          <h4>{t('cancellation')}</h4>
          <div>
            {t('cancellation16')}
          </div>
          <div>
            {t('cancellation17')}
          </div>
          <div>
            {t('cancellation18')}
          </div>
          <div>
            {t('cancellation19')}
          </div>
          <div>
            {t('cancellation20')}
          </div>
          <div>
            {t('cancellation21')}
          </div>
          <div>
            {t('cancellation22')}
          </div>
          <div>
            {t('cancellation23')}
          </div>
          <div>
            {t('cancellation24')}
          </div>
        </div>
        <div className="termsContentDetails">
          <h4>{t('company')}</h4>
          <div>
            {t('company25')}
          </div>
          <div>
            {t('company26')}
          </div>
          <div>
            {t('company27')}
          </div>
          <div>
            {t('company28')}
          </div>
          <div>
            {t('company29')}
          </div>
          <div>
            {t('company30')}
          </div>
          <div>
            {t('company31')}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
