import './Experiences.css';
import Navbar from '../Home/Navbar';
import Footer from '../Home/Footer';
import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import experienceData from './Json/Experiences.json';
import experienceDataEs from './Json/ExperiencesEs.json';
import { useNavigate } from 'react-router-dom';
import { Icons } from '../Common/Icons/Icons';
import { useTranslation } from 'react-i18next';
const Experiences = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.experiences.${value}`);
  const selectedExperiencesData = language === 'es' ? experienceDataEs : experienceData;
  const navigate = useNavigate();
  const handleExperienceClick = (experience: { title: string; cardBackgroundImage: string; experienceDetails: string; images?: any }, experienceId: number) => {
    // Navigate to ExperiencesDetails and pass title, experienceDetails, and images as state
    navigate('/experiences-details', {
      state: {
        ...experience,
        experienceId
      }
    });
  };

  return (
    <div className={'exMainDiv'}>
      <Navbar />
      <div className={'exHeader'}>
        <div className='exSeperator-hidden' />
        <div className='faqTitle'>{t('experiencesTitle')}</div>
        <div className='exLineGold' />
      </div>
      <div className='experiencesHeader'>{t('essentials')}</div>
      <div className='experiencesDiv'>
        {selectedExperiencesData.slice(0, 3).map((experience, experienceId) => (
          <div
            key={experienceId}
            className='mainExperienceDiv'
            onClick={() => handleExperienceClick(experience, experienceId)}
          >
            <img src={require(`../../assets/images/${experience.cardBackgroundImage}`)} alt={experience.title} className='exCardImage'/>
            <div className="exCard">
              <div className='exTitle' >{experience.title}</div>
            </div>
          </div>
        ))}
      </div>
      <div className='experienceBgGoldLogo'>
        <div className='experienceGoldLogo'>
          <Icons.LogoFilled width={880} height={680} />
        </div>
      </div>
      <div className='experiencesHeader'>{t('events')}</div>
      <div className='experiencesDiv'>
        {selectedExperiencesData.slice(3, 6).map((experience, experienceId) => (
          <div
            key={experienceId}
            className='mainExperienceDiv'
            onClick={() => handleExperienceClick(experience, experienceId+3)}
          >
            <img src={require(`../../assets/images/${experience.cardBackgroundImage}`)} alt={experience.title} className='exCardImage' />
            <div className="exCard">
              <div className='exTitle' >{experience.title}</div>
            </div>
          </div>
        ))}
      </div>
      <div className='experiencesDiv'>
        {selectedExperiencesData.slice(6, 9).map((experience, experienceId) => (
          <div
            key={experienceId}
            className='mainExperienceDiv'
            onClick={() => handleExperienceClick(experience, experienceId+6)}
          >
            <img src={require(`../../assets/images/${experience.cardBackgroundImage}`)} alt={experience.title} className='exCardImage' />
            <div className="exCard">
              <div className='exTitle' >{experience.title}</div>
            </div>
          </div>
        ))}
      </div>
      <div className='experienceBgGoldLogo'>
        <div className='experienceGoldLogo'>
          <Icons.LogoFilled width={880} height={680} />
        </div>
      </div>
      <div className='experiencesHeader'>{t('waterSports')}</div>
      <div className='experiencesDiv'>
        {selectedExperiencesData.slice(9, 12).map((experience, experienceId) => (
          <div
            key={experienceId}
            className='mainExperienceDiv'
            onClick={() => handleExperienceClick(experience, experienceId+9)}
          >
            <img src={require(`../../assets/images/${experience.cardBackgroundImage}`)} alt={experience.title} className='exCardImage' />
            <div className="exCard">
              <div className='exTitle' >{experience.title}</div>
            </div>
          </div>
        ))}
      </div>
      <div className='experiencesDiv'>
        {selectedExperiencesData.slice(12, 15).map((experience, experienceId) => (
          <div
            key={experienceId}
            className='mainExperienceDiv'
            onClick={() => handleExperienceClick(experience, experienceId+12)}
          >
            <img src={require(`../../assets/images/${experience.cardBackgroundImage}`)} alt={experience.title} className='exCardImage' />
            <div className="exCard">
              <div className='exTitle' >{experience.title}</div>
            </div>
          </div>
        ))}
      </div>
      <div className='experienceBgGoldLogo'>
        <div className='experienceGoldLogo'>
          <Icons.LogoFilled width={880} height={680} />
        </div>
      </div>
      <div className='experiencesHeader'>{t('extras')}</div>
      <div className='experiencesDiv'>
        {selectedExperiencesData.slice(15, 18).map((experience, experienceId) => (
          <div
            key={experienceId}
            className='mainExperienceDiv'
            onClick={() => handleExperienceClick(experience, experienceId+15)}
          >
            <img src={require(`../../assets/images/${experience.cardBackgroundImage}`)} alt={experience.title} className='exCardImage' />
            <div className="exCard">
              <div className='exTitle' >{experience.title}</div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Experiences;
