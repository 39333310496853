import React, {
  useEffect,
  useState
} from 'react';
import Navbar from '../Home/Navbar';
import './AboutUs.css';
import Footer from '../Home/Footer';
import slide1 from '../../assets/images/slider/slide1.webp';
import slide2 from '../../assets/images/slider/slide2.webp';
import slide3 from '../../assets/images/slider/slide3.webp';
import { useTranslation } from 'react-i18next';
import bg from '../../assets/images/AboutUsBg.webp';

const AboutUs = () => {
  const [currentPhoto, setCurrentPhoto] = useState(0);
  const photos: string[] = [slide1, slide2, slide3];
  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.aboutUs.${value}`);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhoto((prevPhoto) => (prevPhoto + 1) % photos.length);
    }, 3000); // Change photo every 3 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [photos.length]);
  
  return (
    <div>
      <div className='mainContainer1ab'>
        <img src={bg} alt="Container 1 image" className='abImage' />
        <div className='container1Ab'>
          <Navbar />
          <div className={'abHeading'}>
            <div className='abSeperator-hidden' />
            <div className='abTitle'>{t('title')}</div>
            <div className={'abLineGold'} />
          </div>
        </div>
      </div>
      <div className={'container2Ab'}>
        <div className={'container2Content'}>
          <div className={'abGrid1'}>
            <div className={'abGridTitle1'}>{t('secondTitle')}</div>
            <div className='aboutus-description-section'>
              <div className={'abGridText1'}>
                {t('paragraph1')}
              </div>
              <div>
                {t('paragraph2')}
              </div>
              <div>
                {t('paragraph3')}
              </div>
              <div>
                {t('paragraph4')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'container3Ab'}>
        {photos.map((photo, index) => (
          <img
            key={index}
            src={photo}
            alt={`Slide ${index + 1}`}
            className={`slide-image ${index === currentPhoto ? 'visible' : 'hidden'}`}
          />
        ))}
      </div>
      <div className='abFooter'>
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;
