import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

const Stepper = () => {
  const [step, setStep] = useState(1);
  const [boattrip, setBoatTrip] = useState({
    numberOfGuests: '',
    experience: '',
    destination: '',
    boat: '',
    description: {
      nameOfTrip: '',
      date: '',
      name: '',
      number: '',
      email: '',
      description: '',
    },
  });
 
  console.log(boattrip );
  return (
    <>
      {step === 1 ? (
        <Step1 boattrip={boattrip} setBoatTrip={setBoatTrip} setStep={setStep}/>
      ) : (
        step === 2 ? (
          <Step2 boattrip={boattrip} setBoatTrip={setBoatTrip} setStep={setStep}/>
        ) : (
          step === 3 ? (
            <Step3 boattrip={boattrip} setBoatTrip={setBoatTrip} setStep={setStep}/>
          ) : (
            step === 4 ? (
              <Step4 boattrip={boattrip} setBoatTrip={setBoatTrip} setStep={setStep}/>
            ) : (
              <Step5 boattrip={boattrip} setBoatTrip={setBoatTrip} setStep={setStep}/>
            )
          )
        )
      )}
    </>
  );

  
};

export default Stepper;
