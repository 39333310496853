import Navbar from '../Home/Navbar';
import React, { useState } from 'react';
import Footer from '../Home/Footer';
import backArrow from '../../assets/images/backArrow.svg';
import './ExperiencesDetails.css';

import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import leftArrow from '../../assets/images/leftArrow.svg';
import rightArrow from '../../assets/images/rightArrow.svg';
import { Icons } from '../Common/Icons/Icons';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import experienceDetailsData from './Json/ExperiencesDetails.json';
import experienceDetailsDataEs from './Json/ExperienceDetailsEs.json';
const ExperiencesDetails = () => {
  const { t: translate } = useTranslation();
  const t = (value: string) => translate(`t:components.vesselsDetails.${value}`);
  const location = useLocation();
  // const experienceDetails = location.state?.experienceDetails;
  // const title = location.state?.title;
  const images = location.state?.images;
  const experienceId = location.state?.experienceId;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const selectedExperiencesData = i18n.language === 'es' ? experienceDetailsDataEs : experienceDetailsData;
  const selectedExperience = selectedExperiencesData.find((experience: { id: any; }) => experience.id === experienceId+1);

  
  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const divStyle = {
    width: '100%',
    height: '100vh',
  };
  const navigate = useNavigate();
  return (
    <div className='moreInfoDiv' style={divStyle}>
      <Navbar />
      <div className='experienceContent'>
        <div className='experienceLogoBgSection'>
          <div className='experienceLogoBg'>
            <Icons.PuntaBg width={650} height={450} />
          </div>
        </div>
        <div className='moreInfoHeader'>
          <div onClick={() => { navigate('/experiences'); }} className='backArrowContainer'>
            <img src={backArrow} alt='Back arrow' className='backArrow' />
          </div>
          <div className='moreInfoTitle'>
            <div className='moreInfoSeperator-hidden' />
            <div className='moreInfoTitleSmall'>{selectedExperience?.title}</div>
            <div className='moreInfoSeperator-hidden' />
          </div>
          <div className='moreInfoTitleText'>{selectedExperience?.title}</div>
          <div className='moreInfoLineGold' />
        </div>
        <div className='moreInfoMain'>
          <div className='moreInfoCol1'>
            <div style={{ whiteSpace: 'pre-line' }} >{selectedExperience?.experienceDetails}</div>
          </div>
          <div className='moreInfoCol2'>
            <div className='moreInfoClick'>{t('click')}</div>
            <div className='bigImageContainerExperience' onClick={handleImageClick}>
              <img src={require(`../../assets/images/experiences/${images[0]}`)} alt="Main Image" className="bigImageExperience" />
            </div>
            <div className='moreInfoCounter'>1/{images.length}</div>
            {isModalOpen && (
              <div className="modal-overlay">
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                  <div className='modalLeft'>
                    <div onClick={handleModalClose} className='ModalBackArrow'>
                      <img src={backArrow} alt='Back arrow' style={{ cursor: 'pointer' }} />
                    </div>
                    <div>
                      <img src={leftArrow} alt='Left arrow' onClick={handlePreviousImage} style={{ cursor: 'pointer' }} />
                    </div>
                  </div>
                  <img src={require(`../../assets/images/experiences/${images[currentImageIndex]}`)} className="modal-image" alt='Background image'/>
                  <div >
                    <img src={rightArrow} alt='Right arrow' onClick={handleNextImage} style={{ cursor: 'pointer' }} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='moreinfo-footer'>
        <Footer />
      </div>
    </div>
  );
};


export default ExperiencesDetails;
